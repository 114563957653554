<template>
  <button :class="cls" :disabled="connected" @click.prevent="connect">{{ label }}</button>
</template>

<script>
export default {
  name: 'ConnectionButton',
  computed: {
    connected() {
      return this.$store.state.connected;
    },
    label() {
      return !this.connected ? 'Connect' : 'Connected';
    },
    cls() {
      const classes = ['connection-button'];
      classes.push(this.connected ? 'success' : 'primary');
      return classes;
    },
  },
  methods: {
    async connect() {
      try {
        this.$store.commit('toggleLoading', true);
        await this.$store.dispatch('connect');
        this.$store.commit('toggleLoading', false);
      } catch (err) {
        this.$store.commit('toggleLoading', false);
      }
    },
  },
};
</script>

<style scoped>

</style>
