<template>
  <div>
    <modal-close/>
    <h3>Wrap my city at {{ coordinates.row }}, {{ coordinates.column }}</h3>
    <p>Please confirm the wrapping operation.</p>
    <p>An NFT will be wrapped around your base contract city. City name will remain the same.</p>

    <div class="buttons">
      <button class="primary" type="submit" @click.prevent="wrap">Wrap</button>
    </div>
  </div>
</template>

<script>
import ModalClose from '@/components/ui/ModalClose.vue';

export default {
  name: 'WrapModal',
  components: { ModalClose },
  computed: {
    coordinates() {
      return this.$store.state.selectedCellCoordinates;
    },
  },
  methods: {
    wrap() {
      console.log('wrap');
    },
  },
};
</script>

<style scoped>

</style>
