<template>
  <div class="settings-menu">
    <a href="#" class="menu-button"><i class="icon-cog"/></a>
    <div class="menu-content">
      <a @click.prevent="openAboutModal">About</a>
      <a @click.prevent="openSettingsModal">API Settings</a>
    </div>
  </div>
</template>

<script>
import AboutModal from '@/components/ui/AboutModal.vue';
import SettingsModal from '@/components/ui/SettingsModal.vue';

export default {
  name: 'SettingsMenu',
  methods: {
    openAboutModal() {
      this.$modal.show(AboutModal, null, {
        classes: 'reservation-modal',
        width: 400,
        height: 507,
      });
    },
    openSettingsModal() {
      this.$modal.show(SettingsModal, null, {
        classes: 'reservation-modal',
        width: 400,
        height: 507,
      });
    },
  },
};
</script>

<style scoped>

</style>
