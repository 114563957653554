<template>
  <div>
    <modal-close/>
    <h3>API Settings</h3>
    <form @submit.prevent="save">
      <div class="form-control">
        <label>API URL</label>
        <input type="text" name="apiUrl" v-model="apiUrl"/>
        <div class="error" v-if="error">{{ error }}</div>
      </div>

      <div class="buttons">
        <button class="primary" @click.prevent="reset">Reset to default</button>
        <button class="primary" type="submit">Save</button>
      </div>
    </form>
  </div>
</template>

<script>
import ModalClose from '@/components/ui/ModalClose.vue';

export default {
  name: 'SettingsModal',
  components: { ModalClose },
  data() {
    return {
      apiUrl: '',
      error: null,
    };
  },
  mounted() {
    this.apiUrl = this.$store.state.apiUrl;
  },
  methods: {
    async save() {
      this.error = null;

      if (!this.checkUrl()) {
        this.error = 'Invalid url';
      } else {
        try {
          this.$store.commit('toggleLoading', true);
          await this.$store.dispatch('updateApiUrl', this.apiUrl);
          await this.$store.dispatch('getCellData');
          this.$store.commit('toggleLoading', false);
        } catch (err) {
          this.$store.commit('toggleLoading', false);
        }
      }
    },
    async reset() {
      this.apiUrl = 'default';
      await this.save();
    },
    checkUrl() {
      let url;

      if (this.apiUrl === 'default') {
        return true;
      }

      try {
        url = new URL(this.apiUrl);
      } catch (_) {
        return false;
      }

      return url.protocol === 'http:' || url.protocol === 'https:';
    },
  },
};
</script>

<style scoped>

</style>
