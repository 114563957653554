<template>
  <a href="#" @click.prevent="close" class="modal-close">
    <i class="icon-cross"></i>
  </a>
</template>

<script>
export default {
  name: 'ModalClose',
  methods: {
    close() {
      this.$modal.hideAll();
    },
  },
};
</script>

<style scoped>

</style>
