<template>
  <div class="modal-body">
    <modal-close/>
    <div class="image">
      <img :src="imageUrl" :alt="propertyData.name"/>
    </div>
    <div class="texts">
      <vuescroll :ops="scrollOptions">
        <div class="content">
          <div><strong>Name:</strong> {{ propertyData.name }}</div>
          <div><strong>tokenId (cityId):</strong> {{ propertyId }} ({{ propertyData.cityId }})</div>
          <div><strong>Location (row, column):</strong> {{ coordinates.row }}, {{
              coordinates.column
            }}
          </div>
          <div><strong>Owner:</strong> {{ truncatedAddress }} <a href="#" @click.prevent="copy"><i
            class="icon-copy"></i></a></div>
          <div><strong>Buildings:</strong> {{ propertyData.buildings }}</div>
          <div><strong>Description:</strong>
            <p>{{ propertyData.description }}</p></div>
          <div><strong>Opensea:</strong> <a :href="propertyData.openseaLink" target="_blank">
            link</a></div>
        </div>
      </vuescroll>
    </div>
  </div>
</template>

<script>
import vuescroll from 'vuescroll';
import ModalClose from '@/components/ui/ModalClose.vue';

export default {
  name: 'PropertyModal',
  components: {
    ModalClose,
    vuescroll,
  },
  data() {
    return {
      scrollOptions: {
        vuescroll: {
          sizeStrategy: 'number',
        },
        scrollButton: {
          enable: true,
          background: '#000',
        },
        bar: {
          opacity: 0,
          size: '20px',
        },
        rail: {
          size: '20px',
          // opacity: 1,
        },
      },
    };
  },
  computed: {
    propertyData() {
      return this.$store.state.selectedCellData;
    },
    propertyId() {
      return this.$store.state.selectedCellId;
    },
    coordinates() {
      return this.$store.state.selectedCellCoordinates;
    },
    imageUrl() {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return this.propertyId ? `/img/${this.propertyId}.png` : '';
    },
    truncatedAddress() {
      const first = this.propertyData?.owner.substring(0, 6);
      const last = this.propertyData?.owner.substring(this.propertyData?.owner.length - 4);

      return `${first}...${last}`;
    },
  },
  methods: {
    async copy() {
      await this.$copyText(this.propertyData.owner);
    },
  },
};
</script>

<style scoped>

</style>
