<template>
  <header>
    <div class="logo">
      <img src="~@/assets/img/logo.png" alt="Etherization"/>
    </div>
    <div class="controls">
      <connection-button/>
      <zoom-buttons/>
      <settings-menu/>
    </div>
  </header>
</template>

<script>
import ZoomButtons from '@/components/layout/ZoomButtons.vue';
import SettingsMenu from '@/components/layout/SettingsMenu.vue';
import ConnectionButton from '@/components/ui/ConnectionButton.vue';

export default {
  name: 'MainHeader',
  components: {
    ConnectionButton,
    SettingsMenu,
    ZoomButtons,
  },
};
</script>
