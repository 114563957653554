<template>
  <div>
    <modal-close/>
    <h3>Build a city at {{ coordinates.row }}, {{ coordinates.column }}</h3>
    <p>Please confirm the building operation.</p>
    <p>The cost is 0.88 ETH. A city NFT with a building (farm, stables, woodwork or metalwork)
      is randomly assigned during the transaction, and the NFTs ownership and state
      input into the 2016 base contract.</p>
    <label>
      I have read and I accept
      <router-link to="/files/terms.pdf" target="_blank">terms and conditions</router-link>
      <input type="checkbox" v-model="terms"/>
    </label>

    <div class="buttons">
      <button class="primary" type="submit" :disabled="buttonDisabled" @click.prevent="confirm">
        Confirm
      </button>
    </div>
  </div>
</template>

<script>
import ModalClose from '@/components/ui/ModalClose.vue';

export default {
  name: 'ReservationModal',
  components: { ModalClose },
  data() {
    return {
      terms: false,
    };
  },
  computed: {
    coordinates() {
      return this.$store.state.selectedCellCoordinates;
    },
    buttonDisabled() {
      return !this.terms || !this.$store.state.connected;
    },
  },
  methods: {
    async confirm() {
      this.$modal.hideAll();
      await this.$ethActive.getWrapper()
        .methods.mint(this.$store.state.selectedCellCoordinates.row,
          this.$store.state.selectedCellCoordinates.column).send(
          { from: this.$ethActive.getMyAddress(), value: 880000000000000000 },
        );
      console.log('confirmed');
    },
  },
};
</script>

<style scoped>

</style>
