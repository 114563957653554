<template>
  <div>
    <wrapper>
      <container/>
    </wrapper>
  </div>
</template>

<script>
import Wrapper from '@/components/layout/Wrapper.vue';
import Container from '@/components/battlefield/Container.vue';

export default {
  name: 'Home',
  components: {
    Container,
    Wrapper,
  },
  metaInfo: {
    title: 'Etherization',
  },
  mounted() {
    this.$store.dispatch('loadApiUrl');
    this.loadCells();
  },
  methods: {
    async loadCells() {
      try {
        this.$store.commit('toggleLoading', true);
        await this.$store.dispatch('getCellData');
        this.$store.commit('toggleLoading', false);
      } catch (err) {
        this.$store.commit('toggleLoading', false);
      }
    },
  },
};
</script>
