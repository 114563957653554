import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

const apiUrlKey = 'apiUrl';
const openseaPrefix = 'https://opensea.io/assets/0x8b19a0b00eadb34ade0803062fee1e96e13a2dfd/';

export default new Vuex.Store({
  state: {
    loading: false,
    apiUrl: 'default',
    map: [],
    ntfs: [],
    transform: null,
    selectedCellId: null,
    selectedCellData: null,
    selectedCellCoordinates: {},
    // ownerOf: [[0, 0], [0, 1], [10, 15]],
    ownerOf: [],
    connected: false,
  },
  mutations: {
    toggleLoading(state, status) {
      state.loading = status;
    },
    setTransform(state, transform) {
      state.transform = transform;
    },
    setMap(state, data) {
      state.map = data;
    },
    setNtfs(state, data) {
      state.ntfs = data;
    },
    setSelectedCellId(state, cellId) {
      state.selectedCellId = cellId;
    },
    setSelectedCellData(state, data) {
      state.selectedCellData = data;
    },
    setSelectedCellCoordinates(state, coordinates) {
      state.selectedCellCoordinates = coordinates;
    },
    setApiUrl(state, url) {
      state.apiUrl = url;
    },
    setConnection(state) {
      state.connected = true;
    },
  },
  actions: {
    loadApiUrl({ commit }) {
      const ls = localStorage.getItem(apiUrlKey);

      if (ls) {
        commit('setApiUrl', ls);
      }
    },
    updateApiUrl({ commit }, url) {
      if (url === 'default') {
        localStorage.removeItem(apiUrlKey);
      } else {
        localStorage.setItem(apiUrlKey, url);
      }

      commit('setApiUrl', url);
    },
    async getCellData({
      commit,
      state,
      dispatch,
    }) {
      // access api url from state
      const { apiUrl } = state;
      console.log(apiUrl);

      const startTime = performance.now();

      let map = await this._vm.$wrapperPassive.methods.getMap().call();
      map = map.map((r) => r.map((c) => parseInt(c, 10)));
      let nfts = await this._vm.$wrapperPassive.methods.getNfts().call();
      nfts = nfts.map((r) => r.map((c) => parseInt(c, 10)));

      // simulate data from api
      /* commit('setMap', [
        [3, 1, 0, 2, 1],
      ]); */

      /* commit('setNtfs', [
        [3, 0, 0, 2, 0],
      ]); */

      let ownerOf = [];
      if (this._vm.$ethActive && window.ethereum.selectedAddress) {
        commit('setConnection');
        ownerOf = await dispatch('initMyBcData');
      }
      // ADD OWNEROF FOR old cities !!

      const endTime = performance.now();
      const loaderTime = 2000 - (endTime - startTime);
      console.log(loaderTime);
      if (loaderTime > 0) {
        // 2 seconds minus what was spent loading the data
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, loaderTime);
        });
      }

      commit('setMap', map);
      commit('setNtfs', nfts);
      state.ownerOf = ownerOf;
    },

    async selectCell({ commit, state }, cellId) {
      commit('setSelectedCellId', cellId);

      const startTime = performance.now();

      const raw = await axios.get(`/json/${cellId}`);
      const { data } = raw;
      const { attributes } = data;
      const buildings = [];
      attributes.forEach((a) => {
        if (a.trait_type === 'building') {
          buildings.push(a.value);
        }
      });
      const owner = await this._vm.$wrapperPassive
        .methods.ownerOf(cellId).call();
      const cityId = state.map[
        state.selectedCellCoordinates.row][state.selectedCellCoordinates.column] - 1;

      const endTime = performance.now();
      const loaderTime = 1000 - (endTime - startTime);
      if (loaderTime > 0) {
        // 1 seconds minus what was spent loading the data
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, loaderTime);
        });
      }

      commit('setSelectedCellData', {
        owner,
        cityId,
        name: data.name,
        description: data.description,
        buildings: buildings.toString().replace(/,(?=[^\s])/g, ', '),
        openseaLink: openseaPrefix + cellId.toString(),
      });

      /* commit('setSelectedCellData', {
        name: 'Town name',
        description: 'Maecenas vestibulum mollis diam.'
          + 'Pellentesque habitant morbi tristique senectus et netus et'
          + 'malesuada fames ac turpis egestas. Praesent turpis. Nunc nec neque.',
        buildings: 'metalworks',
      }); */
    },

    async connect({ commit, state, dispatch }) {
      const ownerOf = await dispatch('initMyBcData');
      if (this._vm.$ethActive && window.ethereum.selectedAddress) {
        commit('setConnection');
      }
      state.ownerOf = ownerOf;
    },

    async initMyBcData() {
      const ownerOf = [];
      try {
        await this._vm.$ethActive.init();
        const myAddress = this._vm.$ethActive.getMyAddress();
        const myBalance = await this._vm.$ethActive.getWrapper()
          .methods.balanceOf(myAddress).call();
        let row;
        let col;
        for (let i = 0; i < myBalance; i += 1) {
          const tokenId = await this._vm.$ethActive.getWrapper()
            .methods.tokenOfOwnerByIndex(myAddress, i).call();
          row = await this._vm.$ethActive.getWrapper()
            .methods.position(tokenId, 0).call();
          row = parseInt(row, 10);
          col = await this._vm.$ethActive.getWrapper()
            .methods.position(tokenId, 1).call();
          col = parseInt(col, 10);
          ownerOf.push([row, col]);
        }
      } catch (e) {
        console.log(e);
      }
      return ownerOf;
    },
  },
  getters: {
    getBusyIds: (state) => {
      const busyIds = [];

      state.ntfs.forEach((r) => {
        busyIds.push(...r.filter((c) => !!c));
      });

      return busyIds;
    },
    getCellsPerSector: (state) => {
      const cellsPerSector = [];

      // eslint-disable-next-line no-plusplus
      for (let rowIndex = 0; rowIndex < 34; rowIndex++) {
        const mapColumns = state.map[rowIndex] || [];
        const ntfsColumns = state.ntfs[rowIndex] || [];
        const sectorRow = rowIndex < 17 ? 0 : 1;

        if (!cellsPerSector[sectorRow]) {
          cellsPerSector.push([[], []]);
        }

        // eslint-disable-next-line no-plusplus
        for (let columnIndex = 0; columnIndex < 34; columnIndex++) {
          const sectorColumn = columnIndex < 17 ? 0 : 1;
          const newRowIndex = sectorRow ? rowIndex - 17 : rowIndex;

          if (!cellsPerSector[sectorRow][sectorColumn][newRowIndex]) {
            cellsPerSector[sectorRow][sectorColumn][newRowIndex] = [];
          }

          const isOwner = !!state.ownerOf.find((c) => c[0] === rowIndex && c[1] === columnIndex);

          cellsPerSector[sectorRow][sectorColumn][newRowIndex].push({
            row: rowIndex,
            column: columnIndex,
            blank: !mapColumns[columnIndex],
            reserved: !!(mapColumns[columnIndex] && mapColumns[columnIndex] > 0
              && !ntfsColumns[columnIndex]),
            busy: !!(mapColumns[columnIndex] && mapColumns[columnIndex] > 0
              && ntfsColumns[columnIndex] && ntfsColumns[columnIndex] > 0),
            id: ntfsColumns[columnIndex],
            isOwner,
          });
        }
      }

      return cellsPerSector;
    },
  },
  modules: {},
});
