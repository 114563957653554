<template>
  <div id="container" ref="container">
    <div class="battlefield" ref="battlefield">
      <div class="beach">
        <div class="grid" ref="grid">
          <template v-for="(sectorRow, index) in sectors">
            <div :class="['sector-row', `sector-row-${index}`]" :key="index">
              <template v-for="(sectorColumn, index) in sectorRow">
                <div :class="['sector-column', `sector-column-${index}`]" :key="index">
                  <template v-for="(row, index) in sectorColumn">
                    <div class="row" :key="index">
                      <template v-for="cell in row">
                        <cell :key="cell.row + '' + cell.column" :cell="cell" :drag="drag"
                              @click="handleCellClick"/>
                      </template>
                    </div>
                  </template>
                </div>
              </template>
            </div>
          </template>
        </div>
        <div class="bl"></div>
        <div class="bb"></div>
        <div class="br"></div>
        <div class="bt"></div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-mixed-operators */
import Panzoom from '@panzoom/panzoom';
import Cell from '@/components/battlefield/Cell.vue';
import { EventBus } from '@/eventBus';
import PropertyModal from '@/components/battlefield/PropertyModal.vue';
import ReservationModal from '@/components/battlefield/ReservationModal.vue';
import WrapModal from '@/components/battlefield/WrapModal.vue';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'Container',
  components: {
    Cell,
  },
  data() {
    return {
      rows: 34,
      columns: 34,
      zoomInstance: null,
      drag: false,
      scale: 1,
    };
  },
  computed: {
    sectors() {
      return this.$store.getters.getCellsPerSector;
    },
  },
  methods: {
    async handleCellClick(data) {
      const {
        cell,
        row,
        column,
      } = data;
      this.$store.commit('setSelectedCellCoordinates', {
        row,
        column,
      });
      try {
        this.$store.commit('toggleLoading', true);
        if (cell.busy) {
          await this.$store.dispatch('selectCell', cell.id);
          this.$modal.show(PropertyModal, null, {
            classes: 'property-modal',
            width: 575,
            height: 700,
          });
        }

        if (cell.blank) {
          this.$modal.show(ReservationModal, null, {
            classes: 'reservation-modal',
            width: 400,
            height: 507,
          });
        }

        if (cell.reserved && cell.isOwner) {
          this.$modal.show(WrapModal, null, {
            classes: 'reservation-modal',
            width: 400,
            height: 507,
          });
        }

        if (cell.id) {
          await this.$router.replace(`/${cell.id}`);
        } else if (this.$route.params.townId) {
          await this.$router.replace('/');
        }
        this.$store.commit('toggleLoading', false);
      } catch (err) {
        this.$store.commit('toggleLoading', false);
      }
    },
  },
  mounted() {
    const {
      battlefield,
      container,
    } = this.$refs;
    const parent = container.getBoundingClientRect();
    const parentDimension = parent.height < parent.width ? parent.height : parent.width;
    const elementDimensions = battlefield.getBoundingClientRect();
    const elementHeight = elementDimensions.height;
    const newScale = parentDimension / elementHeight;
    const parentCenterX = parent.width / 2;
    const parentCenterY = (parent.height) / 2;

    this.zoomInstance = Panzoom(battlefield, {
      animate: true,
      startScale: newScale,
      minScale: 0.08,
    });

    battlefield.addEventListener('panzoomchange', (event) => {
      this.scale = event.detail.scale;
    });

    setTimeout(() => {
      const nd = battlefield.getBoundingClientRect();
      const newCenterX = (nd.x + (nd.width / 2));
      const newCenterY = (nd.y + (nd.height / 2));
      const cx = (parentCenterX - newCenterX) / newScale;
      const cy = (parentCenterY - newCenterY) / newScale;
      this.zoomInstance.pan(cx, (cy + (70 / newScale)));
    }, 100);

    // enable mouse wheel zoom
    battlefield.parentElement.addEventListener('wheel', this.zoomInstance.zoomWithWheel);

    const scaleRatio = 0.05;
    EventBus.$on('zoom', (dir) => {
      if (dir === 'in') {
        this.scale += scaleRatio;
        this.zoomInstance.zoomToPoint(this.scale, {
          clientX: parent.width / 2,
          clientY: parent.height / 2,
        });
      } else {
        this.scale -= scaleRatio;
        this.zoomInstance.zoomToPoint(this.scale, {
          clientX: parent.width / 2,
          clientY: parent.height / 2,
        });
      }
    });
  },
  beforeDestroy() {
    EventBus.$off('zoom');
  },
};
</script>
