import Vue from 'vue';
import VModal from 'vue-js-modal';
import { VueHammer } from 'vue2-hammer';
import VueMeta from 'vue-meta';
import VueClipboard from 'vue-clipboard2';
import VueGtag from 'vue-gtag';
import BcInit from './plugins/bcInit';
import '@/assets/scss/style.scss';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

Vue.use(VueMeta);
Vue.use(VModal);
Vue.use(VueHammer);
Vue.use(BcInit);
Vue.use(VueClipboard);
Vue.use(VueGtag, {
  config: {
    id: 'G-LVTZP1HSPK',
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
