<template>
  <div :class="classes" v-hammer:tap="handleClick">
    <img v-if="imageUrl" :src="imageUrl" :alt="cellId"/>
  </div>
</template>

<script>
export default {
  name: 'Cell',
  props: {
    cell: {
      type: Object,
      required: true,
    },
  },
  computed: {
    row() {
      return this.cell.row;
    },

    column() {
      return this.cell.column;
    },

    reserved() {
      return !!this.cell?.reserved;
    },

    busy() {
      return !!this.cell?.busy;
    },

    blank() {
      return !!this.cell?.blank;
    },

    imageUrl() {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return this.cellId ? `/img/${this.cellId}.png` : '';
    },

    cellId() {
      return this.cell?.id;
    },

    isOwner() {
      return this.cell.isOwner;
    },

    isInUrl() {
      const { townId } = this.$route.params;

      return townId && this.cellId && townId === `${this.cellId}`;
    },

    classes() {
      const c = ['cell'];

      if (this.reserved) {
        c.push('reserved');
      } else if (this.busy) {
        c.push('busy');
      } else {
        c.push('blank');
      }

      if (this.isOwner) {
        c.push('owner');
      }

      if (this.isInUrl) {
        c.push('selected');
      }

      return c;
    },
  },
  methods: {
    handleClick() {
      this.$emit('click', {
        cell: this.cell,
        row: this.row,
        column: this.column,
      });
    },
  },
};
</script>
