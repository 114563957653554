<template>
  <div id="wrapper">
    <main-header></main-header>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import MainHeader from '@/components/layout/MainHeader.vue';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'Wrapper',
  components: { MainHeader },
};
</script>

<style scoped>

</style>
