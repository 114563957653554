<template>
  <div>
    <modal-close/>
    <h3>About Us</h3>
    <p>Etherization cities are the first in-game NFTs on Ethereum.</p>
    <p>Deployed on September 16, 2016, Etherization was a blockchain-based strategy
      game comprised of 1,156 cities of varying rarity spread over a 34 by 34 map.</p>
    <p>In the game, players could attack other cities to try and capture them and
      earn ETH through ownership of production buildings. Making Etherization the
      first play-to-earn game on Ethereum and one of the first on any blockchain.</p>
    <p><a href="https://about.etherization.org/" target="_blank">Read more</a></p>
  </div>
</template>

<script>
import ModalClose from '@/components/ui/ModalClose.vue';

export default {
  name: 'AboutModal',
  components: { ModalClose },
};
</script>

<style scoped>

</style>
