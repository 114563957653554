import { render, staticRenderFns } from "./WrapModal.vue?vue&type=template&id=0322a856&scoped=true&"
import script from "./WrapModal.vue?vue&type=script&lang=js&"
export * from "./WrapModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0322a856",
  null
  
)

export default component.exports