<template>
  <div id="app">
    <router-view/>
    <loader/>
  </div>
</template>
<script>
import Loader from '@/components/ui/Loader.vue';

export default {
  components: { Loader },
};
</script>
