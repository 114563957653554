import Web3 from 'web3';
import wrapperAbi from '../assets/wrapperAbi.json';

const apiUrlDefault = 'https://mainnet.infura.io/v3/0720cc9944a54891b9c9c87d18e8d019';
const wrapperAddress = '0x8b19A0B00EaDb34Ade0803062feE1E96E13a2Dfd';

class EthActive {
  async init() {
    this.web3 = new Web3(window.ethereum);
    await window.ethereum.enable();
    this.wrapper = new this.web3.eth.Contract(wrapperAbi, wrapperAddress);
    const myAccounts = await this.web3.eth.getAccounts();
    [this.myAddress] = myAccounts;
    /* const ethBalance = await this.web3.eth.getBalance(this.myAddress);
    console.log(this.myAddress);
    console.log(ethBalance); */
  }

  getWeb3() {
    return this.web3;
  }

  getWrapper() {
    return this.wrapper;
  }

  getMyAddress() {
    return this.myAddress;
  }
}

export default {
  // called by Vue.use(FirstPlugin)
  async install(Vue) {
    let apiUrl = localStorage.getItem('apiUrl');
    if (!apiUrl) {
      apiUrl = apiUrlDefault;
    }

    let ethActive;
    if (window.ethereum) {
      ethActive = new EthActive();
    }
    const web3Passive = new Web3(apiUrl);
    const wrapperPassive = new web3Passive.eth.Contract(wrapperAbi, wrapperAddress);

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$web3Passive = web3Passive;
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$wrapperPassive = wrapperPassive;
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$ethActive = ethActive;
  },
};
