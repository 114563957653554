<template>
  <div class="zoom">
    <button @click.prevent="zoomOut">
      <i class="icon-zoom-out"></i>
    </button>
    <button @click.prevent="zoomIn">
      <i class="icon-zoom-in"></i>
    </button>
  </div>
</template>

<script>
import { EventBus } from '@/eventBus';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'ZoomButtons',
  methods: {
    zoomOut() {
      EventBus.$emit('zoom', 'out');
    },
    zoomIn() {
      EventBus.$emit('zoom', 'in');
    },
  },
};
</script>

<style scoped>

</style>
